import { Paper, Box, Typography, Stack, Card, CardContent, Skeleton } from "@mui/material";

/**
 * 
 * @param {Object} param 
 * @param {string} param.title
 * @param {string} param.subtitle
 */
const StatCard = ({title, subtitle, loading, children, ...props}) => {

    return (
        <Card {...props}>
            <CardContent>
                <Box>
                    <Typography fontSize="13px" sx={{borderBottom: 'dotted 2px', display: 'inline-block'}}>{title}</Typography>
                </Box>
                <Box marginBottom="5px">
                    <Typography fontSize="13px" sx={{display: 'inline-block', lineHeight: '1'}}>{subtitle}</Typography>
                </Box>
                {loading ? <Skeleton /> : children}
            </CardContent>
        </Card>
    );
}

export default StatCard;