import { useState, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, Typography } from '@mui/material';

const OrderTable = ({order, onOrderEdit, onDelete}) => {
    const [totalPerSKU, setTotalPerSKU] = useState({});
    const [totalPerStore, setTotalPerStore] = useState({});
    const [total, setTotal] = useState();
    const [newItem, setNewItem] = useState({
        barcode: "",
        name: "",
        size: "",
        sku: "",
    });
    const largeScreen = useMediaQuery('(min-width:900px)');

    useEffect(() => {
        if (!order) return;
        
        let nextTotalPerStore = {};
        let nextTotalPerSKU = {};
        let nextTotal = 0;
        for (const item of order.items) {
            if (nextTotalPerSKU[item.sku] === undefined) {
                nextTotalPerSKU[item.sku] = 0;
            }
            for (const store of order.stores) {
                if (nextTotalPerStore[store] === undefined) {
                    nextTotalPerStore[store] = 0;
                }
                nextTotalPerStore[store] += item.qty[store] || 0;
                nextTotalPerSKU[item.sku] += item.qty[store] || 0;
                nextTotal += item.qty[store] || 0;
            }
        }
        setTotalPerStore(nextTotalPerStore);
        setTotalPerSKU(nextTotalPerSKU);
        setTotal(nextTotal);
    }, [order]);

    const handleNewItemChange = ({key, value}) => {
        setNewItem({...newItem, [key]: value});
    }

    const nextNewItem = () => {
        const currentSize = parseInt(newItem.size);
        if (!isNaN(currentSize) && currentSize <= 48) {
            return setNewItem({
                barcode: "",
                name: newItem.name,
                size: currentSize + 1,
                sku: newItem.sku.replace(/\d{2}$/, currentSize + 1),
            });
        }
        setNewItem({
            barcode: "",
            name: "",
            size: "",
            sku: "",
        });
    }

    return order ? (
        <TableContainer>
        <Table stickyHeader size="small">
          <TableHead sx={{bgcolor: (theme) => theme.palette.common.black}}>
            <TableRow>
              {largeScreen && <TableCell>NAME</TableCell>}
              {largeScreen && <TableCell align="right">SIZE&nbsp;EU</TableCell>}
              <TableCell>SKU</TableCell>
              <TableCell>Barcode</TableCell>
              {order.stores.map((store) => {
                return <TableCell key={store} sx={{minWidth:'5rem'}} align="right">Qty {store.toUpperCase()}</TableCell>
              })}
              <TableCell align="right">TOTAL</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.items
                .sort((a, b) => a.sku.localeCompare(b.sku))
                .map(item => {
                return (
                    <TableRow
                        key={item.sku}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        {largeScreen && <TableCell align="left"><Typography noWrap>{item.name}</Typography></TableCell>}
                        {largeScreen && <TableCell align="right">{item.size}</TableCell>}
                        <TableCell><Typography noWrap>{item.sku.toUpperCase()}</Typography></TableCell>
                        <TableCell><Typography noWrap>{item.barcode}</Typography></TableCell>
                        {order.stores.map(store => {
                            return (<TableCell key={store} align="right">
                            {!!onOrderEdit 
                                ? <TextField
                                    hiddenLabel
                                    id="filled-hidden-label-small"
                                    defaultValue={item.qty[store] || 0}
                                    variant="filled"
                                    size="small"
                                    inputProps={{inputMode: 'numeric', pattern: '[0-9]*', style: {textAlign: 'right'}}}
                                    onChange={(evt) => onOrderEdit({store, sku:item.sku, name:item.name, size:item.size, qty:evt.target.value})}
                                    />
                                : item.qty[store] || 0}
                            </TableCell>)
                        })}
                        <TableCell align="right">{totalPerSKU[item.sku]}</TableCell>
                        <TableCell align="right">{onDelete && <Button color='primary' onClick={() => onDelete(item.sku)}>X</Button>}</TableCell>
                    </TableRow>
                )
            })}
            {!!onOrderEdit && <TableRow
                key="new-sku"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                {largeScreen && <TableCell align="left">
                    <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        value={newItem.name}
                        variant="filled"
                        size="small"
                        placeholder='Name'
                        onChange = {(evt) => handleNewItemChange({key: 'name', value: evt.target.value})}
                        />
                    </TableCell>}
                {largeScreen && <TableCell align="right">
                    <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        value={newItem.size}
                        variant="filled"
                        size="small"
                        placeholder='Sz'
                        inputProps={{inputMode: 'numeric', pattern: '[0-9]*', style: {textAlign: 'right'}}}
                        onChange = {(evt) => handleNewItemChange({key: 'size', value: evt.target.value})}
                        />
                    </TableCell>}
                <TableCell><Typography noWrap>
                    <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        value={newItem.sku}
                        variant="filled"
                        size="small"
                        placeholder='SKU'
                        onChange = {(evt) => handleNewItemChange({key: 'sku', value: evt.target.value})}
                        />
                </Typography></TableCell>
                <TableCell><Typography noWrap>
                    <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        value={newItem.barcode}
                        variant="filled"
                        size="small"
                        placeholder='Barcode'
                        onChange = {(evt) => handleNewItemChange({key: 'barcode', value: evt.target.value})}
                        />
                </Typography></TableCell>
                {order.stores.map(store => {
                    return (<TableCell key={store} align="right">                             
                    </TableCell>)
                })}
                <TableCell align="right"></TableCell>
                <TableCell align="right"><Button disabled={!newItem.name || !newItem.size || !newItem.sku || !newItem.barcode} onClick={() => {
                    onOrderEdit(newItem);
                    nextNewItem();
                }}>Ajouter</Button></TableCell>
            </TableRow>}
            <TableRow
                key={"total"}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell align="left" colSpan={largeScreen ? 4 : 2}>TOTAL</TableCell>
                {order.stores.map(store => {
                    return <TableCell key={store} align="right">{totalPerStore[store]}</TableCell>
                })}
                <TableCell align="right">{total}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    ) : "Loading";
}

export default OrderTable;