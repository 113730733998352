const names = {
    "CORRIDA_NOIR": "Corrida Black",
    "CORRIDA_BLANC": "Corrida White",
    "SD-BLK": "Slim Dança Black",
    "SD-WHI": "Slim Dança White",
    "CORRIDALTA_NOIRE": "Corridalta Black",
    "CORRIDALTA_BLANC": "Corridalta White",
    "MD-BLK": "Montante Black",
    "MD-WHI": "Montante White",
    "CF-BLK": "Crossfeet Black/White Sole",
    "URBANO_NOIR_BLANC": "Urbano Black/White",
    "URBANO_BLANC": "Urbano White",
    "URBANO_NOIR": "Urbano Black/Black Sole"
}

const getName = (sku) => {
    return names[sku.replace(/-\d+/, '')] ?? "";
}

export default getName;