import { useState, useEffect } from 'react';
import { createOrder } from '../../firebase.js';
import getName from '../../utils/getName.js';

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";

import OrderTable from './OrderTable.js';


const OrderCreateModal = ({initialQties, barcodes, closeModal, reseller=false}) => {
    const [reference, setReference] = useState('');
    const [address, setAddress] = useState({
        name: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    });
    const [order, setOrder] = useState();

    useEffect(() => {
        const today = new Date();
        const defaultRef = `${today.getFullYear().toString().slice(-2)}${(today.getMonth()+1) >= 10 ? today.getMonth()+1 : '0'+(today.getMonth()+1).toString()}P`;
        setReference(defaultRef);
    }, []);

    useEffect(() => {
        if (reseller) {
            return setOrder({items: [], notes: [], stores:["Revendeur"]})
        }
        if (initialQties && barcodes && !order) {
            /* 
                Format order data as will be uploaded to firebase.
                {
                    reference
                    createdAt
                    stores [
                        name
                    ]
                    items [{
                        barcode
                        name
                        size
                        sku
                        qty {
                            store: qty
                        }
                    }]
                    notes [{
                        user
                        note
                    }]
                }
            */
            const stores = [];
            for (const sku in initialQties) {
                for (const store in initialQties[sku]) {
                    if (!stores.includes(store)) {
                        stores.push(store);
                    }
                }
            }
            const nextOrder = {
                stores,
                items: Object.entries(initialQties).map(([sku, qty]) => {
                    const barcode = barcodes[sku];
                    const filteredQty = {};
                    for (const store in qty) {
                        if (stores.includes(store)) {
                            filteredQty[store] = qty[store];
                        }
                    }
                    return {
                        barcode,
                        name: getName(sku),
                        size: parseInt(sku.slice(-2)),
                        sku,
                        qty: filteredQty
                    }
                }),
                notes: []
            }
            setOrder(nextOrder);
        }
    }, [])

    const handleChange = (evt) => {
        setReference(evt.target.value);
    }

    const handleChangeAddress = (change) => {
        setAddress({...address, ...change});
    }

    const handleCreateOrder = async () => {
        closeModal();
        await createOrder(reference, reseller ? {...order, address} : order, 'production');
    }

    const handleOrderEdit = ({barcode, store, sku, qty, name, size}) => {
        const newOrder = {...order};
        const item = newOrder.items.find((item) => item.sku === sku);
        if (!item) {
            const qty = {};
            newOrder.stores.forEach(store => {
                qty[store] = 0;
            });
            newOrder.items.push({
                barcode,
                name,
                size,
                sku,
                qty,
            });
        } else {
            item.qty[store] = parseInt(qty);
        }
        setOrder(newOrder);
    }

    const handleDeleteSku = (sku) => {
        const newOrder = {...order};
        newOrder.items = order.items.filter(item => item.sku !== sku);
        setOrder(newOrder);
    }

    return (
        <Stack spacing={"10px"}>
            <Typography>Entrer le numéro de référence</Typography>
            <TextField
                required
                id="reference"
                label="Référence"
                placeholder="yyMMP"
                onChange={handleChange}
                value={reference}
            />
            {reseller && <>
            <TextField
                required
                id="addressName"
                label="Nom"
                onChange={(evt) => handleChangeAddress({ name: evt.target.value })}
                defaultValue={address.name}
            />
            {/*
            <TextField
                required
                id="addressL1"
                label="Ligne 1"
                onChange={(evt) => handleChangeAddress({ line1: evt.target.value })}
                defaultValue={address.line1}
            />
            <TextField
                id="addressL2"
                label="Ligne 2"
                onChange={(evt) => handleChangeAddress({ line2: evt.target.value })}
                defaultValue={address.line2}
            />
            <TextField
                required
                id="addressCity"
                label="Ville"
                onChange={(evt) => handleChangeAddress({ city: evt.target.value })}
                defaultValue={address.city}
            />
            <TextField
                id="addressState"
                label="État/Province"
                onChange={(evt) => handleChangeAddress({ state: evt.target.value })}
                defaultValue={address.state}
            />
            <TextField
                required
                id="addressZip"
                label="Zip/Code Postal"
                onChange={(evt) => handleChangeAddress({ zip: evt.target.value })}
                defaultValue={address.zip}
            />
            <TextField
                required
                id="addressCountry"
                label="Pays"
                onChange={(evt) => handleChangeAddress({ country: evt.target.value })}
                defaultValue={address.country}
            />*/}
            </>}
            <OrderTable order={order} onOrderEdit={handleOrderEdit} onDelete={handleDeleteSku} />
            <Button
                disabled={reseller && !address.name} variant="contained" onClick={handleCreateOrder}>Confirmer</Button>
        </Stack>
    )
} 

export default OrderCreateModal;