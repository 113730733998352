import {useState, useEffect} from 'react';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../firebase.js';
import { graphQLReq } from '../../shopify-storefront.js';
import { getFunctions, httpsCallable } from "firebase/functions";

import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DataGrid } from '@mui/x-data-grid';
import WarningIcon from '@mui/icons-material/Warning';

const Inventory = ({setTitle, setLoading, setMenuItems}) => {
  const [brazilInventory, setBrazilInventory] = useState();
  const [shopifyInventory, setShopifyInventory] = useState();
  const [inventory, setInventory] = useState([]);
  
  useEffect(() => {
    setTitle('INVENTAIRE');
    setLoading(true);
    const unsubscribe = onSnapshot(doc(db, "state", "current-inventory"), (doc) => {
      setBrazilInventory(JSON.parse(doc.data().jsonString));
      console.log("Got Brazil inventory");

    });
    fetchShopifyInventory();
    const interval = setInterval(() => {
      fetchShopifyInventory();
    }, 60*60*1000);
    return () => {
      unsubscribe();
      clearInterval(interval);
    }
  }, [setLoading, setTitle]);

  const fetchShopifyInventory = async () => {
    let nextShopifyInventory = [];
    let endCursor;
    let hasNextPage;
    do {
      const query = `
      {
        products(${endCursor ? 'after:"' + endCursor + '", ': ''}first:250) {
          nodes{
            id
            title
            variants(first:250) {
              nodes {
                barcode
                sku
                brazilInventory: metafield(namespace:"inventory", key:"brazil") {
                  value
                }
              }
            }
          }
          pageInfo{
            hasNextPage
            endCursor
          }
        }
      }
      `;
      const {data} = await graphQLReq(query);
      data.products.nodes.forEach(p => nextShopifyInventory.push(...p.variants.nodes.map(v => ({
          productId: p.id,
          title: p.title,
          variantId: v.id,
          barcode: v.barcode,
          sku: v.sku,
          sQty: parseInt(v.brazilInventory?.value || null),
          inShopify: true,
        })
      )));
      hasNextPage = data.products.pageInfo.hasNextPage;
      endCursor = data.products.pageInfo.endCursor;
    } while (hasNextPage);
    console.log('Got Shopify inventory');
    setShopifyInventory(nextShopifyInventory);
  }

  useEffect(() => {
    if (!shopifyInventory || !brazilInventory)
      return;

    let nextIndex = 0;
    const nextInventory = [];
    for (const shopifyItem of shopifyInventory) {
      const brazilItem = brazilInventory.find(i => i[0] === shopifyItem.barcode);
      const nextItem = {...shopifyItem};
      if (brazilItem) {
        nextItem.inBrazil = true;
        nextItem.bQty = brazilItem[1];
      } else {
        nextItem.inBrazil = false;
      }
      if (!nextItem.sku && !nextItem.barcode) {
        nextItem.id = nextIndex++;
      } else {
        nextItem.id = nextItem.sku + "-" + nextItem.barcode;
      }
      nextInventory.push(nextItem);
    }
    for (const brazilItem of brazilInventory) {
      const inventoryItem = nextInventory.find(i => i.barcode === brazilItem[0]);
      if (!inventoryItem) {
        nextInventory.push({
          barcode: brazilItem[0],
          bQty: brazilItem[1],
          id: nextIndex++,
          inBrazil: true,
        })
      }
    }
    setInventory(nextInventory);
    setLoading(false)
  }, [shopifyInventory, brazilInventory]);

  const archive = async (productId) => {
    const functions = getFunctions();
    const archiveProduct = httpsCallable(functions, 'shopify-archiveProduct');
    const success = await archiveProduct({productId});
    if (success) {
      // Remove product from list on success
      const nextInventory = inventory.filter(item => item.productId !== productId);
      setInventory(nextInventory);
    }
  }

  const columns = [
    { 
      field: 'title', 
      headerName: 'Title',
       width: 220 
    },
    {
      field: 'sku',
      headerName: 'SKU',
      width: 220,
    },
    {
      field: 'barcode',
      headerName: 'Barcode',
      width: 90,
    },
    {
      field: 'sQty',
      headerName: 'SQty',
      type: 'number',
      width: 90,
    },
    {
      field: 'bQty',
      headerName: 'BQty',
      type: 'number',
      width: 90,
    },
    {
      field: 'qty-issue',
      headerName: 'Qty Issue',
      renderCell: (params) => {
        return params.row.sQty !== params.row.bQty ? <WarningIcon color='warning' /> : ''
      },
      width: 90,
    },
    {
      field: 'inShopify',
      headerName: 'In Shopify',
      renderCell: (params) => {
        return !!params.value ? <CheckCircleIcon color={params.row.inBrazil === params.row.inShopify ? 'success' : 'warning'} /> : <CancelIcon color='error' />
      },
      width: 90,
    },
    {
      field: 'inBrazil',
      headerName: 'In Brazil',
      renderCell: (params) => {
        return !!params.value ? <CheckCircleIcon color={params.row.inBrazil === params.row.inShopify ? 'success' : 'warning'} /> : <CancelIcon color='error' />
      },
      width: 90,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => {
        return !!params.row.inBrazil ? "" : <Button onClick={() => archive(params.row.productId)}>Archive</Button>
      },
      width: 90,
    },
  ];

  return (
    <DataGrid
        rows={inventory}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100]}
        checkboxSelection
        disableRowSelectionOnClick
      />
  )
}

export default Inventory;