import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";

const useAuth = () => {
    const auth = getAuth();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (newUser) => {
            setLoading(true);
            if (newUser) {
                try {
                    // Get the ID token
                    const idTokenResult = await newUser.getIdTokenResult();
                    
                    // Access custom claims
                    const {role} = idTokenResult.claims;
                    const isAdmin = role === 'admin' || idTokenResult.claims?.admin === true;
                    const isStaff = role === 'staff';
                    const isSupplier = role === 'supplier';

                    setUser({...newUser, isAdmin, isStaff, isSupplier});
                } catch (error) {
                    console.error("Error fetching custom claims:", error);
                    setUser(newUser);
                }
            } else {
                setUser(null);
            }
            setLoading(false);
        });
        return () => unsubscribe()
    }, [auth]);

    return {
        user,
        loading
    }
}

export default useAuth;