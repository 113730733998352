import {useState, useEffect} from 'react';
import { listUsers, setRole } from '../../firebase.js';
import { Grid, Toolbar, Typography, Stack, MenuItem, Paper } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import useAuth from '../../Hooks/useAuth.js';

import Box from '@mui/material/Box';

function Users({setTitle, setLoading, setMenuItems}) {
    const [users, setUsers] = useState();
    const [loadingRole, setLoadingRole] = useState({});
    const {user} = useAuth();

    useEffect(() => {
        setMenuItems();
      }, [setMenuItems]);

    useEffect(() => {
        setTitle('UTILISATEURS')
        getUsers();
    },[]);
    
    const getUsers = async () => {
        setLoading(true);
        const u = await listUsers();
        setUsers(u)
        setLoading(false);
    }

    const handleRoleChange = async ({evt, uid}) => {
        setLoadingRole({...loadingRole, [uid]: true});
        await setRole(uid, evt.target.value);
        await getUsers();
        setLoadingRole({...loadingRole, [uid]: false});
    }

    if (!user?.isAdmin) {
        return <Typography fontSize="18px">Accès non autorisé</Typography>
    }

    return (
    <>
      
      <Toolbar />
      {users &&
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
            <Stack
                sx={{maxWidth: '600px', margin: '14px auto'}}
                direction="row"
                spacing={2}>
                <Paper variant="outlined" sx={{padding: '8px 12px', flexGrow: 1}}>
                <Box>
                <Typography fontSize="11px" sx={{borderBottom: 'dotted 2px', display: 'inline-block'}}>{users.length} users</Typography>
                </Box>
                </Paper>
            </Stack>
            <Stack 
                sx={{maxWidth: '600px', margin: '14px auto'}} 
                spacing={2}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Display Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Last Seen</TableCell>
                                <TableCell>Role</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map(user => (
                                <TableRow
                                    key={user.uid}
                                >
                                    <TableCell component="th" scope="row">
                                        {user.displayName}
                                    </TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.metadata.lastRefreshTime && Intl.DateTimeFormat('fr-CA', {dateStyle: 'short', timeStyle: 'short'}).format(new Date(user.metadata.lastRefreshTime))}</TableCell>
                                    <TableCell width={200} align='center'>
                                    {!loadingRole[user.uid] ? (
                                        <Select
                                            id={user.uid + '_role'}
                                            value={user.customClaims?.role || 'none'}
                                            onChange={(evt) => handleRoleChange({evt, uid: user.uid})}
                                            size='small'
                                        >
                                            <MenuItem value={'none'}>
                                            <em>Aucun</em>
                                            </MenuItem>
                                            <MenuItem value={'admin'}>Administrateur</MenuItem>
                                            <MenuItem value={'staff'}>Employé</MenuItem>
                                            <MenuItem value={'supplier'}>Fournisseur</MenuItem>
                                        </Select>
                                    ) : (
                                        <CircularProgress size={18} />
                                    )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
            </Grid>
        </Grid>
      }
    </>
    );
  }
  
  export default Users;