
/**
 * 
 * @param {string} shippingCountryCode the shipping destination country code. Ex: US.
 * @returns the region name (USA, CANADA, or GERMANY)
 */
const regionFromCountryCode = (shippingCountryCode = "") => {
  switch (shippingCountryCode.toUpperCase()) {
    case 'CA':
      return 'CANADA';
    case 'BE':
    case 'BG':
    case 'CZ':
    case 'DK':
    case 'DE':
    case 'EE':
    case 'IE':
    case 'EL':
    case 'ES':
    case 'FR':
    case 'HR':
    case 'IT':
    case 'CY':
    case 'LV':
    case 'LT':
    case 'LU':
    case 'HU':
    case 'MT':
    case 'NL':
    case 'AT':
    case 'PL':
    case 'PT':
    case 'RO':
    case 'SI':
    case 'SK':
    case 'FI':
    case 'SE':
    case 'IS':
    case 'LI':
    case 'NO':
    case 'CH':
    case 'UK':
    case 'BA':
    case 'ME':
    case 'MD':
    case 'MK':
    case 'AL':
    case 'RS':
    case 'TR':
    case 'UA':
    case 'XK':
    case 'GE':
    case 'AM':
    case 'BY':
    case 'AZ':
    case 'DZ':
    case 'EG':
    case 'IL':
    case 'JO':
    case 'LB':
    case 'LY':
    case 'MA':
    case 'PS':
    case 'SY':
    case 'TN':
    case 'RU':
      return 'GERMANY';
    default:
      return 'USA';
  }
}

export { regionFromCountryCode }