const graphQLURL = process.env.REACT_APP_SHOPIFY_GRAPHQL_URL;
const accessToken = process.env.REACT_APP_SHOPIFY_PUBLIC_ACCESS_TOKEN;

export const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Sends a graphQL request to the US Shopify store
 * @param {string} query
 * @param {Object?} variables
 * @param {Object} config
 * @param {number} config.retry The current number of retries.
 * @param {number} config.retryForSec The total number of seconds until the function stops retrying.
 * @param {boolean} config.longRunning If true, will add a delay after each call to fully restore the query points to support looping queries like getting all products.
 * @return {Promise<GraphQLResponse>}
 */
export const graphQLReq = async (query, variables = {}, config = {}) => {
  const startTime = Date.now();
  const {retry, retryForSec, longRunning} = {
    retry: 0,
    retryForSec: 50,
    longRunning: false,
    ...config,
  };
  const response = await fetch(graphQLURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Shopify-Storefront-Access-Token": accessToken,
    },
    body: JSON.stringify({query, variables}),
  });

  if (!response.ok) {
    // TODO: handle error status codes
    throw new Error(`HttpError: ${response.status} ${response.statusText}.`);
  }

  const result = await response.json();

  // Check if there is an error
  if (result.errors) {
    const throttleStatus = result.extensions?.cost?.throttleStatus;
    if (result.errors.find((error)=>error.extensions?.code === "THROTTLED")) {
      // The request was throttled
      // retry
      // Calculate the newDelay
      const actualQueryCost = result.extensions.cost.actualQueryCost;
      const maximumAvailable = throttleStatus.maximumAvailable;
      const currentlyAvailable = throttleStatus.currentlyAvailable;
      const restoreRate = throttleStatus.restoreRate;
      const newDelay = longRunning || (retry >= 1) ?
        (maximumAvailable - currentlyAvailable) / restoreRate * 1000 : // ms until full replenish
        (actualQueryCost - currentlyAvailable) / restoreRate * 1000; // ms

      // Check if the new delay is still within the retryForSec time
      const nextRetryForSec = retryForSec - ((Date.now() - startTime) + newDelay) / 1000; // seconds
      if (nextRetryForSec < 0) {
        // max retries reached
        throw new Error(`THROTTLED - Max retries reached. Aborting. \n result: ${result}`);
      }

      await exports.timeout(newDelay);

      return await exports.graphQLReq(query, variables, {retry: retry + 1, retryForSec: nextRetryForSec});
    }
    // Other error
    for (const error of result.errors) {
      console.error(error.message);
    }
    throw new Error("Received errors from graphQL. Aborting.");
  }
  return result;
};
