import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

function LoadingButton({ children, startIcon, disabled, loading=false, ...restOfProps}) {
  return (
      <Button startIcon={loading ? <CircularProgress size={20} thickness={5} /> : startIcon} disabled={loading ? true : disabled} {...restOfProps}>
          {children}
      </Button>
  )
}

export default LoadingButton;