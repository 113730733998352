// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
    getFirestore,
    query,
    onSnapshot,
    collection,
    where,
    addDoc,
    getDocs,
    updateDoc,
    doc,
    getDoc,
    increment,
    arrayUnion,
    orderBy,
    limit,
    connectFirestoreEmulator,
    deleteDoc,
    runTransaction,
} from "firebase/firestore";
import { 
  connectFunctionsEmulator,
  getFunctions, 
  httpsCallable
} from "firebase/functions";
import { 
  GoogleAuthProvider,
  getAuth, 
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  connectAuthEmulator
} from "firebase/auth";
import { regionFromCountryCode } from "./utils/regionFromCountryCode";

const app = initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
});
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

/** COMMENT OUT FOR PRODUCTION */
if (process.env.NODE_ENV === 'development') {
  console.log('Connecting emulators');
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectFirestoreEmulator(db, 'localhost', 8080);
} else {
  console.log(`Connecting to live project '${process.env.REACT_APP_PROJECT_ID}'`);
}


/** Google Authentication */
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert('this error:' + err.message);
  }
}

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

/**
 * @callback lineItemsCallback
 * 
 * @param {QuerySnapshot} snapshot
 */

/**
 * 
 * @param {lineItemsCallback} callback 
 * @returns Unsubscribe function from firestore.
 */
const streamLineItems = (callback) => {
    const q = query(collection(db, "orders-line-items"), where("brazilFulfillment", "==", true), where("open", "==", true));
    const unsubscribe = onSnapshot(q, callback, (error) => { 
      console.log(error); 
      throw error;
      //logout();
    });
    return unsubscribe;
}

/**
 * 
 * @param {lineItemsCallback} callback 
 * @returns Unsubscribe function from firestore.
 */
const streamClosedLineItems = (callback) => {
  // Create a new Date object for the current date
let currentDate = new Date();

// Set the date to the first day of the current month
let firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

// Subtract one month to get the last month
let firstDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
firstDayOfPreviousMonth.setMonth(firstDayOfCurrentMonth.getMonth() - 1);

// Get the timestamp (in milliseconds since January 1, 1970)
let timestamp = firstDayOfPreviousMonth.getTime();
  const q = query(collection(db, "orders-line-items"), where("brazilFulfillment", "==", true), where("open", "==", false), where("createdAt", ">=", timestamp));
  const unsubscribe = onSnapshot(q, callback, (error) => { 
    console.log(error); 
    throw error;
    //logout();
  });
  return unsubscribe;
}

/**
 * 
 * @param {lineItemsCallback} callback 
 * @returns Unsubscribe function from firestore.
 */
const streamOverReservedLineItems = (callback) => {
  const q = query(collection(db, "orders-line-items"), where("brazilFulfillment", "==", false), where("reservedQty", ">", 0));
  const unsubscribe = onSnapshot(q, callback, (error) => { 
    console.log(error); 
    throw error;
    //logout();
  });
  return unsubscribe;
}


/**
 * 
 * @param {string} encodedLineItemId 
 * @returns The customer data
 */
 const getCustomerData = async (encodedLineItemId) => {
  const docRef = doc(db, "orders-line-items", encodedLineItemId, "private", "customer");
  const docSnap = await getDoc(docRef);
  return docSnap.data();
}

const updateNotes = (docId, notes) => {
  const ref = doc(db, "orders-line-items", docId);
  updateDoc(ref, { notes });
}

/**
 * 
 * @param {Object|Object[]} events
 * @param {'adjustment'|'order'|'comment'} events.type The type of event
 * @param {string?} events.key The key that was adjusted
 * @param {number?} events.qty The quantity of the adjustment
 * @param {string} events.orderRef The ref of the order
 * @param {string} events.comment The comment
 * @return {FieldValue} The Fieldvalue sentinel for updating the log object
 */
const createLogItem = (events) => {
  const logItems = [];
  const timestamp = Date.now();
  const user = {
    id: auth.currentUser?.uid,
    name: auth.currentUser?.displayName
  };
  if (events instanceof Array) {
    for (const event of events) {
      logItems.push({
        user,
        timestamp,
        event,
      })
    }
  } else {
    logItems.push({
      user,
      timestamp,
      event: events
    })
  }
  return arrayUnion(...logItems);
}

/**
 * 
 * @param {string} docId 
 * @param {Object} adjustments - An object containing the fields to adjust and the adjustment quantity.
 */
const adjustFieldQty = async (docId, adjustments) => {
  const data = {};
  const logEvents = [];
  for (const key of Object.keys(adjustments)) {
    if (!['reservedQty', 'sentQty', 'unavailableQty'].includes(key)) {
      return;
    }
    logEvents.push({type: 'adjustment', key, qty: adjustments[key]});
    data[key] = increment(adjustments[key]);
  }
  data.log = createLogItem(logEvents);
  const ref = doc(db, "orders-line-items", docId);
  await updateDoc(ref, data);
}

const postComment = async (docId, comment) => {
  const log = createLogItem({type: 'comment', comment});
  const ref = doc(db, "orders-line-items", docId);
  await updateDoc(ref, {log});
}

const getSales = async () => {
  const q = query(collection(db, "sales"), orderBy("createdAt", "desc"), limit(1));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    alert('No sales document found, creating the first one. Reload the page in 2 minutes');
    const getSalesBySku = httpsCallable(functions, 'fabrique-getSales');
    return getSalesBySku();
  }
  return querySnapshot.docs[0].data();
}

const createOrder = async (reference, order, type) => {
  try {
    // Create new order ref
    const newOrderRef = doc(collection(db, "orders"));
    // Updating quantity fields
    await runTransaction(db, async (transaction) => {

      if (type === 'stock') {
        // Phase 1: Read all line items
        const lineItemsUpdates = [];
        const customersObject = {};
        for (const {docId, qty} of order.lineItems ?? []) {
          
            const lineItemRef = doc(db, "orders-line-items", docId);
            const lineItemSnapshot = await transaction.get(lineItemRef);

            if (!lineItemSnapshot.exists()) {
                throw "Line item document does not exist!";
            }

            const customerRef = doc(db, `orders-line-items/${docId}/private/customer`);
            const customerSnapshot = await transaction.get(customerRef);

            if (!customerSnapshot.exists()) {
              throw "Customer document does not exist!";
            }

            const {orderName, name = null, variantName = null, sku = null, imgSrc = null, shippingCountryCode = null} = lineItemSnapshot.data();
            const {email, firstName, lastName} = customerSnapshot.data();
            const fulfillmentCountry = regionFromCountryCode(shippingCountryCode);

            // Initialize customer in customers object if not exist
            if (!customersObject[email]) {
              customersObject[email] = {
                email,
                firstName,
                lastName,
                fulfillmentCountry,
                items: [],
              };
            } else if (customersObject[email].fulfillmentCountry !== fulfillmentCountry) {
              // If we have multiple fulfillment countries, update to "MULTI"
              customersObject[email].fulfillmentCountry = "MULTI";
            }

            // Add item to the order
            customersObject[email].items.push({
              orderName,
              imgSrc,
              name, 
              qty,
              sku,
              variantName,
            });

            // Store the necessary updates in an array for later use
            lineItemsUpdates.push({ ref: lineItemRef, qty, log: createLogItem({type: 'order', orderRef: order.reference, qty}) });
        }

        // Convert customers object to array format
        const customers = Object.values(customersObject);

        // Phase 2: Apply all writes
        const customersRef = doc(db, newOrderRef.path, `private/customers`);
        transaction.set(customersRef, {customers});

        lineItemsUpdates.forEach(({ ref, qty, log }) => {
            transaction.update(ref, { 
                sentQty: increment(qty),
                reservedQty: increment(-qty),
                log,
            });
        });
      }

      const createdAt = new Date();
      transaction.set(newOrderRef, {
        ...order,
        createdAt,
        status: 'new',
        reference,
        type,
      });
    });
    console.log(`Order ${newOrderRef.id} created.`)
  } catch (error) {
    console.error(error);
  }
}

const listUsers = async () => {
  const usersCallable = httpsCallable(functions, 'users-listUsers');
  const {data} = await usersCallable();
  return data;
}

const sendKlaviyoEvent = async ({eventName, orderId}) => {
  const createEvent = httpsCallable(functions, "ordersKlaviyo-sendEvents");
  createEvent({eventName, orderId});
}

const setRole = async (uid, role) => {
  const setRole = httpsCallable(functions, 'users-setRole');
  await setRole({uid, role});
}

const streamOrders = (callback) => {
  const q = query(collection(db, "orders"), orderBy("createdAt", "desc"), limit(20));
  const unsubscribe = onSnapshot(q, callback, (error) => {
    console.log(error);
    throw error;
  } );
  return unsubscribe;
}


const streamNewOrder = (callback) => {
  const q = query(collection(db, "orders"), where("status", "==", "new"));
  const unsubscribe = onSnapshot(q, callback, (error) => {
    console.log(error);
    throw error;
  } );
  return unsubscribe;
}

const editOrder = async (orderId, data) => {
  const ref = doc(db, "orders", orderId);
  await updateDoc(ref, data);
  return true;
}

const deleteOrder = async (order) => {
  try {
    await runTransaction(db, async (transaction) => {
      console.log('deleting order')
      // Mark order for deletion
      const orderRef = doc(db, "orders", order.id);
      // Phase 1: Read all line items
      const lineItemsUpdates = [];
      for (const {docId, qty} of order.lineItems ?? []) {
          const lineItemRef = doc(db, "orders-line-items", docId);
          const lineItemSnapshot = await transaction.get(lineItemRef);

          if (!lineItemSnapshot.exists()) {
              throw "Line item document does not exist!";
          }

          // Store the necessary updates in an array for later use
          console.log({qty})
          lineItemsUpdates.push({ ref: lineItemRef, qty, log: createLogItem({type: 'order', orderRef: order.reference, qty: -qty}) });
          console.log(lineItemsUpdates)
      }

      // Phase 2: Apply all writes
      lineItemsUpdates.forEach(({ ref, qty, log }) => {
          transaction.update(ref, { 
              sentQty: increment(-qty),
              reservedQty: increment(qty),
              log
          });
      });
      transaction.update(orderRef, { markedForDeletion: true });
    });
    // Delete order outside of transaction
    const orderRef = doc(db, "orders", order.id);
    const orderSnapshot = await getDoc(orderRef);
    if (orderSnapshot.exists() && orderSnapshot.data().markedForDeletion) {
        await deleteDoc(orderRef);
        console.log(`Order ${order.reference} deleted.`);
    }
    // Delete private subcollection for stock order
    const orderPrivateRef = doc(db, "orders", order.id, "private/customers");
    const orderPrivateSnapshot = await getDoc(orderPrivateRef);
    if (orderPrivateSnapshot.exists()) {
        await deleteDoc(orderPrivateRef);
        console.log(`Order ${order.reference} private data deleted.`);
    }
  } catch (error) {
      console.error(error);
  }
  return true;
}

/**
 * 
 * @param {pendingProductsCallback} callback 
 * @returns Unsubscribe function from firestore.
 */
const streamPendingProducts = (callback) => {
  const q = query(collection(db, "brazil-inventory-pending"), where("status", "==", "missing"));
  const unsubscribe = onSnapshot(q, callback, (error) => { 
    console.log(error); 
    throw error;
  });
  return unsubscribe;
}

const createShopifyDraft = async (barcode) => {
  const createShopifyDraftProduct = httpsCallable(functions, 'brazilInventory-createShopifyDraftProduct');
  return await createShopifyDraftProduct(barcode);
}

export {
  auth,
  db,
  functions,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  createOrder,
  streamClosedLineItems,
  streamLineItems,
  streamOverReservedLineItems,
  getCustomerData,
  updateNotes,
  adjustFieldQty,
  getSales,
  postComment,
  sendKlaviyoEvent,
  setRole,
  streamOrders,
  streamNewOrder,
  streamPendingProducts,
  createShopifyDraft,
  editOrder,
  deleteOrder,
  listUsers,
};