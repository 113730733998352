const getInitials = (name) => {
  let initials = '';
  const split = name.split(/[-\s]/g);
  for (const component of split) {
      initials += component[0].toUpperCase();
  }
  return initials;
}

const getFirstName = (name) => {
  return name.split[/\s/][0];
}

function stringToColor(string) {
  if (typeof string !== 'string') {
    string = '';
  }
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function getContrastYIQ(hexcolor) {
  const r = parseInt(hexcolor.substr(1, 2), 16);
  const g = parseInt(hexcolor.substr(3, 2), 16);
  const b = parseInt(hexcolor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}

function stringAvatar(name) {
  if (typeof name !== 'string') {
    name = '?';
  }
  return {
    sx: {
      bgcolor: stringToColor(name),
      color: getContrastYIQ(stringToColor(name)),
      ml: '10px',
      cursor: 'pointer'
    },
    children: `${name.split(' ')[0]?.[0]}${name.split(' ')[1]?.[0] || ''}`,
  };
}

module.exports = {
  getInitials,
  getFirstName,
  stringAvatar,
}