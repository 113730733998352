import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DownloadIcon from '@mui/icons-material/Download';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { generateLabels } from '../../utils/generateLabels';
import LoadingButton from '../LoadingButton';


function DownloadLabelsDialog({selectedItems, showDialog, closeDialog}) {
  const [newChecked, setNewChecked] = useState([]);
  const [reservedChecked, setReservedChecked] = useState([]);
  const [sentChecked, setSentChecked] = useState([]);
  const [newItems, setNewItems] = useState([]);
  const [reservedItems, setReservedItems] = useState([]);
  const [sentItems, setSentItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const updatedNewItems = selectedItems.reduce((prev, {
      requiredQty = 0,
      reservedQty = 0, 
      sentQty = 0,
      sku,
      barcode,
      shippingCountryCode,
      orderName,
    }) => {
      const splitItems = [];
      for (let i = 0; i < requiredQty - (reservedQty + sentQty); i++) {
        splitItems.push({ sku, barcode, regionCode: shippingCountryCode, ref: orderName.match(/\d+$/)?.[0] });
      }
      return [...prev, ...splitItems];
    }, []);
    if (newChecked.length) {
      // update newChecked
      setNewChecked(updatedNewItems);
    }
    setNewItems(updatedNewItems);
    const updatedReservedItems = selectedItems.reduce((prev, {
      reservedQty = 0, 
      sku,
      barcode,
      shippingCountryCode,
      orderName,
    }) => {
      const splitItems = [];
      for (let i = 0; i < reservedQty; i++) {
        splitItems.push({ sku, barcode, regionCode: shippingCountryCode, ref: orderName.match(/\d+$/)?.[0] });
      }
      return [...prev, ...splitItems];
    }, []);
    if (reservedChecked.length) {
      // update reservedChecked
      setReservedChecked(updatedReservedItems);
    }
    setReservedItems(updatedReservedItems);
    const updatedSentItems = selectedItems.reduce((prev, {
      sentQty = 0, 
      sku,
      barcode,
      shippingCountryCode,
      orderName,
    }) => {
      const splitItems = [];
      for (let i = 0; i < sentQty; i++) {
        splitItems.push({ sku, barcode, regionCode: shippingCountryCode, ref: orderName.match(/\d+$/)?.[0] });
      }
      return [...prev, ...splitItems];
    }, []);
    if (sentChecked) {
      // update sentChecked
      setSentChecked(updatedSentItems);
    }
    setSentItems(updatedSentItems);

  }, [selectedItems]);

  const handleToggleNew = () => {
    if (!newChecked.length) {
      setNewChecked(newItems);
    } else {
      setNewChecked([]);
    }
  }

  const handleToggleReserved = () => {
    if (!reservedChecked.length) {
      setReservedChecked(reservedItems);
    } else {
      setReservedChecked([]);
    }
  }

  const handleToggleSent = () => {
    if (!sentChecked.length) {
      setSentChecked(sentItems);
    } else {
      setSentChecked([]);
    }
  }

  const handleDownloadLabels = async () => {
    setLoading(true);
    const pdf = await generateLabels([...newChecked, ...reservedChecked, ...sentChecked].sort((a, b) => parseInt(a.ref) - parseInt(b.ref)), "production");
    pdf.save(`Etiquettes_${formattedTimestamp()}.pdf`);
    setLoading(false);
  }

  function formattedTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
  }

  return (
    <Dialog
      open={showDialog}
      onClose={closeDialog}
      maxWidth={'full'}
    >
      <DialogTitle>Télécharger les étiquettes</DialogTitle>
      <DialogContent>
      <p>Sélectioner les étiquettes à télécharger.</p>
      <FormGroup>
        <FormControlLabel disabled={!newItems.length} control={<Checkbox checked={newChecked.length > 0} onChange={handleToggleNew} />} label={`Nouveaux articles (${newItems.length})`} />
        <FormControlLabel disabled={!reservedItems.length} control={<Checkbox checked={reservedChecked.length > 0} onChange={handleToggleReserved} color="success" />} label={`Articles réservées (${reservedItems.length})`} />
        <FormControlLabel disabled={!sentItems.length} control={<Checkbox checked={sentChecked.length > 0} onChange={handleToggleSent} color="secondary" />} label={`Articles envoyés (${sentItems.length})`} />
      </FormGroup>
      </DialogContent>
      <DialogActions>
          <Button onClick={closeDialog}>Annuler</Button><LoadingButton startIcon={<DownloadIcon />} variant="contained" loading={loading} onClick={handleDownloadLabels}>Télécharger</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default DownloadLabelsDialog;