import { useEffect, useState } from "react";
import { graphQLReq } from "../../shopify-storefront";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const SEARCH_PRODUCTS_QUERY = `
  query ($query: String!) {
    predictiveSearch(query: $query, limit: 5, types: [PRODUCT], searchableFields:[TITLE, VARIANTS_TITLE, VARIANTS_SKU]) {
      products {
        id
        title
        images(first:1){
          nodes {
            url(transform: {maxWidth: 30, maxHeight: 30, scale:2})
          }
        }
      }
    }
  }
`;

const ProductSelector = ({ onSelectProduct }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (typeof inputValue !== 'string' || inputValue === '') {
      setOptions([]);
      return;
    }

    const fetchProducts = async () => {
      const variables = { query: inputValue };
      const data = await graphQLReq(SEARCH_PRODUCTS_QUERY, variables);
      const products = data.data.predictiveSearch.products.map(product => ({
        id: product.id,
        title: product.title,
        image: product.images.nodes[0].url || '',
      }));
      setOptions(products);
    };

    const delayDebounceFn = setTimeout(() => {
      fetchProducts();
    }, 300); // Debounce API call by 300ms

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  const handleSelectProduct = (e, newValue) => {
    if (newValue) {
      onSelectProduct(newValue);
      setValue(null); // Clear the Autocomplete input
      setInputValue('');
    } else {
      onSelectProduct(null);
    }
  }

  return (
    <Autocomplete 
      freeSolo
      options={options}
      getOptionLabel={(option) => option.title}
      value={value}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      renderOption={(props, option) => (
        <li {...props}>
          <img src={option.image} alt="" width="40" height="40" style={{ marginRight: 10, objectFit: 'contain', background: 'white' }} />
          {option.title}
        </li>
      )}
      renderInput={(params) => (
        <TextField 
          {...params}
          label="Search Products"
          variant='outlined'
        />
      )}
      onChange={handleSelectProduct}
    />
  )
}

export default ProductSelector;