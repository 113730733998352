import { useState } from 'react';
import { postComment } from '../../firebase';
import { stringAvatar } from '../../utils/users';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField  from '@mui/material/TextField';

export const getMessage = ({event, user}) => {
  const getKeyName = (key) => {
    switch (key) {
      case 'reservedQty':
        return 'reserved quantity';
        break;
      case 'unavailableQty':
        return 'unavailable quantity';
        break;
      case 'sentQty':
        return 'sent quantity';
        break;
      default:
        return key;
    }
  }
  switch (event.type) {
    case 'adjustment':
      return `${user.name} ${event.qty >= 0 ? 'increased' : 'decreased'} ${getKeyName(event.key)} by ${Math.abs(event.qty)}`;
      break;
    case 'order':
      return `${user.name} ${event.qty >= 0 ? 'added' : 'removed'} ${Math.abs(event.qty)} item ${event.qty >= 0 ? 'to' : 'from'} order ${event.orderRef}`;
      break;
    case 'comment':
      return event.comment;
      break;
    case 'text':
      return `${user.name} ${event.text}`;
      break;
    default:
      return '';
  }
}

const LogDialog = ({onClose, lineItem}) => {
  const [comment, setComment] = useState('');

  const handleAddComment = async (event) => {
    if (!comment || !comment.trim()) return;
    postComment(lineItem.id, comment);
    setComment('');
    onClose();
  }

  return <Dialog onClose={onClose} open={!!lineItem}>
    <DialogTitle mr={2}>Log Details {lineItem.orderName} {lineItem.sku}</DialogTitle>
    <DialogContent>
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={(theme) => ({
        position: 'absolute',
        right: 8,
        top: 8,
        color: theme.palette.grey[500],
      })}
    >
      <CloseIcon />
    </IconButton>
    <List dense={true} sx={{ pt: 0 }}>
      {lineItem.log?.map((item) => (
        <ListItem disableGutters key={item.timestamp}>
            <ListItemAvatar>
              {item.event?.type === 'comment' && <Avatar {...stringAvatar(item.user.name)} />}
            </ListItemAvatar>
            <ListItemText primary={getMessage(item)} secondary={Intl.DateTimeFormat('en-CA',{month: 'short', day: 'numeric', year: new Date(item.timestamp).getFullYear() === new Date().getFullYear() ? undefined : 'numeric', hour: 'numeric', minute: '2-digit'}).format(new Date(item.timestamp))} primaryTypographyProps={{color: item.event.type === 'comment' ? undefined : 'gray'}} secondaryTypographyProps={{color:'gray'}} />
        </ListItem>
      ))}
      <ListItem disableGutters>
        <TextField 
          label="Add Comment" 
          variant="outlined" 
          fullWidth={true} 
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault(); // Prevent default behavior (like form submission)
              event.stopPropagation(); // Stop event from propagating further
              handleAddComment();
            }
          }}
          onChange={(event) => {
            setComment(event.target.value);
          }}
          value={comment}
          InputProps={{
            endAdornment: <InputAdornment position="end"><Button onClick={handleAddComment}>Submit</Button></InputAdornment>,
          }}
        />
      </ListItem>
    </List>
    </DialogContent>
  </Dialog>
}

export default LogDialog;