import { useState } from 'react';
import csvDownload from 'json-to-csv-export';
import { getCustomerData } from '../../firebase.js';

import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import GroupIcon from '@mui/icons-material/Group';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import { regionFromCountryCode } from '../../utils/regionFromCountryCode.js';

function CustomerDataMenuItem({lineItems, loading, setLoadingCustomerData}) {
    const [downloaded, setDownloaded] = useState(false);
    const dateSuffix = () => Intl.DateTimeFormat('fr-CA', {dateStyle: 'short', timeStyle: 'short'}).format(new Date()).replace(' ', '_').replace(/\s/g, '');

    const exportCustomerData = async () => {
        setLoadingCustomerData(true);
        try {
            const cd = [];
            const filteredLineItems = lineItems.filter(li => (li.sentQty || 0) > 0 && li.requiredQty > 0)
                .sort((a, b) => a.orderName < b.orderName ? -1 : a.orderName > b.orderName ? 1 : 0);
            for (const li of filteredLineItems) {
                const code = regionFromCountryCode(li.shippingCountryCode);
                const orderName = li.orderName.replace('#','');
                const customerData = await getCustomerData(li.docId);
                // delete null fields (fucks up with klavyio imports)
                Object.keys(customerData).forEach(key => {
                if (customerData[key] === null || customerData[key] === undefined) {
                    customerData[key] = '';
                }
                });
                const existing = cd.find(c => c.email === customerData.email);
                if (existing) {
                const existingOrders = JSON.parse(existing.orders);
                if (!existingOrders.includes(orderName)) {
                    existingOrders.push(orderName);
                    existing.orders = JSON.stringify(existingOrders);
                }
                } else {
                cd.push({
                    code,
                    ...customerData,
                    orders: JSON.stringify([orderName])
                });
                }
            }
            csvDownload({
                data: cd.filter(line => line.code === "CANADA"), 
                filename: `customer-data-taygra_${dateSuffix().replace(' ', '_')}_CA.csv`,
                delimiter: ','
            });
            csvDownload({
                data: cd.filter(line => line.code === "EUROPE"), 
                filename: `customer-data-taygra_${dateSuffix().replace(' ', '_')}_EU.csv`,
                delimiter: ','
            });
            csvDownload({
                data: cd.filter(line => line.code === "USA"), 
                filename: `customer-data-taygra_${dateSuffix().replace(' ', '_')}_US.csv`,
                delimiter: ','
            });
            setDownloaded(true);
        } catch(error) {
            console.log(error)
            setDownloaded(false);
        }
        setLoadingCustomerData(false);
    }

    return (
        <MenuItem disabled={loading} onClick={exportCustomerData}><ListItemIcon>{loading ? <CircularProgress color="inherit" size={24} /> : downloaded ? <DoneIcon color='success' /> : <GroupIcon />}</ListItemIcon><ListItemText>Données Clients (envoyées seulement)</ListItemText></MenuItem>
    );
}

export default CustomerDataMenuItem;