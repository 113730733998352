import { useEffect, useRef, useState } from "react";
import { adjustFieldQty, db } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { Menu, MenuItem, styled, Tooltip, useMediaQuery } from "@mui/material";
import { stringAvatar } from "../../utils/users";
import { getMessage } from "./logDialog";
import ReactDOM from 'react-dom';

import AddCommentIcon from '@mui/icons-material/AddComment';
import Avatar from '@mui/material/Avatar';
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckIcon from '@mui/icons-material/Check';
import Chip from "@mui/material/Chip";
import Dialog from '@mui/material/Dialog';
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from "@mui/material/IconButton";
import InfoIcon from '@mui/icons-material/Info';
import InputAdornment from '@mui/material/InputAdornment';
import Link from "@mui/material/Link";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import Flag from "./Flag";
import { regionFromCountryCode } from "../../utils/regionFromCountryCode";
import { MoreHoriz } from "@mui/icons-material";

function LineItem({ lineItem, openLogDialog, selected, addToSelection, removeFromSelection }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const featuredLog = lineItem.log && lineItem.log.filter(item => !!item.featured || item.event?.type === 'comment').sort((a, b) => b.timestamp - a.timestamp)[0];

  const renderMobileComponent = () => (
    <>
      <Stack spacing={0.5}>
        <Stack direction={'row'} height={75} spacing={2}>
          <Box position={'relative'}>
            <Box
              sx={{
                height: '100%',
                width: '75px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0,
              }}
            >
              <ImageWithPopup src={lineItem.imgSrc} />
            </Box>
            <Box position='absolute' top={0} left={0}>
              <Flag overlay flag={regionFromCountryCode(lineItem.shippingCountryCode)} />
            </Box>
          </Box>
          <Stack height={'100%'} justifyContent={'space-between'} flexGrow={1} flexShrink={1}>
            <Typography fontSize={14} lineHeight={'14px'} color='Gray'>{lineItem.orderName}</Typography>
            <Typography fontSize={14} lineHeight={'14px'} sx={{overflowWrap: 'anywhere'}}>{lineItem.sku}</Typography>
            <Typography fontSize={14} lineHeight={'14px'} color='gray'>x{lineItem.requiredQty}</Typography>
          </Stack>
          <TagBox flexShrink={0} lineItem={lineItem} />
          <Stack alignItems={'flex-end'} justifyContent={'space-between'} height={'100%'}>
            <Box>
              <Typography textAlign={'right'} noWrap fontSize={12} color={'gray'}>{lineItem.date && Intl.DateTimeFormat('en-CA', { month: 'short', day: 'numeric', year: lineItem.date.getFullYear() === new Date().getFullYear() ? undefined : 'numeric' }).format(lineItem.date)}</Typography>
              <Typography textAlign={'right'} noWrap fontSize={12} color={'gray'}>{lineItem.date && Intl.DateTimeFormat('en-CA', { hour: 'numeric', minute: '2-digit' }).format(lineItem.date)}</Typography>
            </Box>
            <ActionsButton variant='outlined' lineItem={lineItem} openLogDialog={openLogDialog} />
          </Stack>
        </Stack>
        {featuredLog && (
          <Stack direction={'row'} gap={1} alignItems={'center'} pl={2}>
            <Avatar {...stringAvatar(featuredLog.user.name)} sx={{ height: 24, width: 24, fontSize: 12 }} />
            <Stack>
              <Typography
                fontSize={12}
                textTransform={'none'}
                textAlign={'left'}
                sx={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 2, // Limits to 2 lines
                  lineHeight: '16px', // Adjust based on your typography settings
                  maxHeight: '32px', // 2 lines * line-height
                }}
              >{getMessage(featuredLog)}</Typography>
              <Typography variant='body2' noWrap fontSize={11} textTransform={'none'} textAlign={'left'} color={'gray'}>{Intl.DateTimeFormat('en-CA', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(featuredLog.timestamp))}</Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  );

  const renderDesktopComponent = () => (
    <Stack direction={'row'} height={51} alignItems={'center'} spacing={2}>
      <Stack flexShrink={0} minWidth={90}>
        <Typography fontSize={14}>{lineItem.date && Intl.DateTimeFormat('en-CA', { month: 'short', day: 'numeric', year: lineItem.date.getFullYear() === new Date().getFullYear() ? undefined : 'numeric' }).format(lineItem.date)}</Typography>
        <Typography fontSize={12} color={'gray'}>{lineItem.date && Intl.DateTimeFormat('en-CA', { hour: 'numeric', minute: '2-digit' }).format(lineItem.date)}</Typography>
      </Stack>
      <Box flexShrink={0}>
        <Flag flag={regionFromCountryCode(lineItem.shippingCountryCode)} />
      </Box>
      <Box
        flexShrink={0}
        sx={{
          height: '100%',
          width: '75px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
        }}
      >
        <ImageWithPopup src={lineItem.imgSrc} />
      </Box>
      <Stack height={'100%'} flexGrow={1} justifyContent={'space-evenly'} flexShrink={0}>
        <Typography fontSize={14} lineHeight={'14px'} color='Gray'>{lineItem.orderName}</Typography>
        <Typography fontSize={14} lineHeight={'14px'} sx={{overflowWrap: 'anywhere'}}>{lineItem.sku}</Typography>
      </Stack>
      <Typography fontSize={14} lineHeight={'14px'} width={'24px'}>x{lineItem.requiredQty}</Typography>
      <TagBox lineItem={lineItem} />
      <Box width={250}>
        {featuredLog && (
          <Stack direction={'row'} gap={1} alignItems={'center'} pl={2}>
            <Avatar {...stringAvatar(featuredLog.user.name)} sx={{ height: 24, width: 24, fontSize: 12 }} />
            <Stack>
              <Typography
                fontSize={12}
                textTransform={'none'}
                textAlign={'left'}
                sx={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 2, // Limits to 2 lines
                  lineHeight: '16px', // Adjust based on your typography settings
                  maxHeight: '32px', // 2 lines * line-height
                }}
              >{getMessage(featuredLog)}</Typography>
              <Typography
                variant='body2'
                fontSize={11}
                textTransform={'none'}
                textAlign={'left'}
                color={'gray'}
                noWrap
              >{Intl.DateTimeFormat('en-CA', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(featuredLog.timestamp))}</Typography>
            </Stack>
          </Stack>
        )}
      </Box>
      <Box flexShrink={0}>
        <ActionsButton variant='outlined' lineItem={lineItem} openLogDialog={openLogDialog}>Actions</ActionsButton>
      </Box>
    </Stack>
  );

  return (
    <>
      {isMobile ? renderMobileComponent() : renderDesktopComponent()}
    </>
  )
}

const ThumbnailContainer = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'relative',
});

const ThumbnailImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transition: 'transform 0.3s ease-in-out',
});

const PopUpImage = styled(Box)(({ top, left, theme }) => ({
  position: 'absolute',
  top: `${top}px`,
  left: `${left}px`,
  transform: 'translate(-50%, -50%)',
  width: '200px',
  height: '200px',
  zIndex: 10,
  display: 'block',
  pointerEvents: 'none',
  [theme.breakpoints.down('sm')]: {
    width: '150px',
    height: '150px',
  },
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

const HoverContainer = styled(Box)({
  height: '100%',
  width: '100%',
  position: 'relative',
});

const ImageWithPopup = ({ src }) => {
  const [visible, setVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      // Set the popup position relative to the container element
      setPopupPosition({
        top: rect.top + window.scrollY + 50, // 50px below the element
        left: rect.left + window.scrollX + 50, // 50px to the right of the element
      });
    }
    if (visible) {
      const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
          setVisible(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [visible]);

  const handleImageClick = () => {
    setVisible((prev) => !prev);
  };
  return (
    <>
      <HoverContainer
        ref={containerRef}
        onClick={handleImageClick}
      >
        <ThumbnailContainer>
          <ThumbnailImage src={src && src.replace('_240x', '_160x')} alt="Thumbnail" />
        </ThumbnailContainer>
      </HoverContainer>
      {/* Render the PopUpImage outside of HoverContainer */}
      {visible && ReactDOM.createPortal(
        <PopUpImage top={popupPosition.top} left={popupPosition.left}>
          <img src={src && src.replace('_240x', '_400x')} alt="Popup" />
        </PopUpImage>,
        document.body
      )}
    </>
  );
};

const TagBox = ({ lineItem, ...props }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  const { id, newQty, requiredQty, reservedQty, sentQty, unavailableQty, unavailableDetail } = lineItem;
  const chips = [];

  const handleLowerReservedQty = async () => {
    adjustFieldQty(id, { reservedQty: -1 });
  }

  const handleLowerUnavailableQty = async () => {
    adjustFieldQty(id, { unavailableQty: -1 });
  }

  const getTitle = () => {
    switch (unavailableDetail) {
      case 'discontinued':
        return 'Discontinued';
      case 'restock-this-month':
        return 'Will be restocked this month';
      case 'restock-next-month':
        return 'Will be restocked next month';
      case 'restock-a-few-months':
        return 'Will be restocked in a few months';
    }
  }

  const title = getTitle();

  for (const tag of lineItem.status) {
    switch (tag) {
      case 'New':
        chips.push(<Chip
          key="new"
          size='small'
          label={newQty + (!isMobile ? ` New` : '')}
          color='primary'
          sx={{
            flexShrink: 1,
            whiteSpace: 'nowrap',
            minWidth: !isMobile && 32,
          }}
        />);
        break;
      case 'Reserved':
        chips.push(<Chip
          key="reserved"
          sx={{
            flexShrink: 1,
            whiteSpace: 'nowrap',
            minWidth: !isMobile && 50,
            boxSizing: 'border-box',
            ...(lineItem.overReserved && {
              boxShadow: 'inset 0 0 4px 12px rgba(244, 67, 54, 1)', // Red glow effect
              '&:hover': {
                boxShadow: 'inset 0 0 12px 4px rgba(244, 67, 54, 1)', // Stronger glow on hover
              },
              mx: '4px',
            }),
          }}
          size='small'
          label={reservedQty + (!isMobile ? ` Reserved` : '')}
          onDelete={handleLowerReservedQty}
          color={'success'}
          
        />);
        break;
      case 'Sent':
        chips.push(<Chip
          key="sent"
          size='small'
          label={sentQty + (!isMobile ? ` Sent` : '')}
          color='secondary'
          sx={{
            flexShrink: 1,
            whiteSpace: 'nowrap',
          }}
        />);
        break;
      case 'Unavailable':
        chips.push(<Tooltip
          key="unavailable"
          title={<Typography fontSize={14}>{title}</Typography>}
          arrow
        >
          <Chip
            label={unavailableQty + (!isMobile ? ` Unavailable` : '')}
            size='small'
            icon={!isMobile ? <InfoIcon /> : null}
            onDelete={handleLowerUnavailableQty}
            color={requiredQty >= sentQty + reservedQty + unavailableQty ? 'warning' : 'error'}
            sx={{
              flexShrink: 1,
              whiteSpace: 'nowrap',
              minWidth: !isMobile && 67,
            }}
          />
        </Tooltip>);
        break;
      case 'Archived':
        chips.push(<Chip
          key="archived"
          size='small'
          label={'Archived'}
          color='archived'
          sx={{
            flexShrink: 1,
            whiteSpace: 'nowrap',
            minWidth: !isMobile && 32,
          }}
        />);
        break;
    }
  }

  return <Box
    {...props}
    ref={containerRef}
    sx={{
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      position: 'relative',
      height: '100%',
    }}>
    <Stack
      ref={contentRef}
      direction='row'
      gap={1}
      height={'100%'}
      alignItems={'center'}
      pr={'5px'}
      sx={{
        minWidth: 0,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      {chips}
    </Stack>
  </Box>
}

function ActionsButton({ lineItem, openLogDialog, children, ...props }) {
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [unavailableAnchorEl, setUnavailableAnchorEl] = useState(null);
  const [restockAnchorEl, setRestockAnchorEl] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const { docId, newQty } = lineItem;

  const handleActionsMenuOpen = (event) => {
    handleUnavailableMenuClose();
    handleRestockMenuClose();
    setActionsAnchorEl(event.currentTarget);
  }

  const handleActionsMenuClose = (event) => {
    setActionsAnchorEl(null);
  }

  const handleUnavailableMenuOpen = (event) => {
    handleActionsMenuClose();
    setUnavailableAnchorEl(event.currentTarget);
  }

  const handleUnavailableMenuClose = (event) => {
    setUnavailableAnchorEl(null);
  }

  const handleRestockMenuOpen = (event) => {
    handleUnavailableMenuClose();
    setRestockAnchorEl(event.currentTarget);
  }

  const handleRestockMenuClose = (event) => {
    setRestockAnchorEl(null);
  }

  const handleOpenLogDialog = () => {
    handleActionsMenuClose();
    openLogDialog(lineItem);
  }

  const handleReserve = async () => {
    if (isUpdating) return;
    setIsUpdating(true);
    handleActionsMenuClose();
    try {
      await adjustFieldQty(docId, { reservedQty: 1 });
    } catch (error) {
      console.error('Error adjusting field qty: ', error);
    } finally {
      setIsUpdating(false);
    }
  }

  const handleUnavailableClick = async (event) => {
    if (isUpdating) return;
    setIsUpdating(true);
    setUnavailableAnchorEl(null);
    setRestockAnchorEl(null);
    try {
      const docRef = doc(db, 'orders-line-items', docId);
      switch (event.currentTarget.id) {
        case 'discontinued':
          await setDoc(docRef, { unavailableDetail: 'discontinued' }, { merge: true });
          break;
        case 'will-restock':
          setRestockAnchorEl(event.currentTarget);
          break;
        case 'this-month':
          await setDoc(docRef, { unavailableDetail: 'restock-this-month' }, { merge: true });
          break;
        case 'next-month':
          await setDoc(docRef, { unavailableDetail: 'restock-next-month' }, { merge: true });
          break;
        case 'a-few-months':
          await setDoc(docRef, { unavailableDetail: 'restock-a-few-months' }, { merge: true });
          break;
      }
      await adjustFieldQty(docId, { unavailableQty: 1 });
    } catch (error) {
      console.error('Error updating document: ', error);
    } finally {
      setIsUpdating(false);
    }


  }

  return <>
    {children ? <Button {...props} size="small" variant='outlined' endIcon={<MoreHoriz />} onClick={handleActionsMenuOpen}>{children}</Button> : <Button {...props} variant='outlined' size="small" onClick={handleActionsMenuOpen}><MoreHoriz /></Button>}
    {ReactDOM.createPortal(
      <>
        <Menu
          id="actions-menu"
          anchorEl={actionsAnchorEl}
          open={!!actionsAnchorEl}
          onClose={handleActionsMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem id="reserve" disabled={isUpdating || !newQty} onClick={handleReserve}>
            <ListItemIcon>
              <SaveAltIcon />
            </ListItemIcon>
            <ListItemText>Reserve</ListItemText>
          </MenuItem>
          <MenuItem id="unavailable" disabled={isUpdating || !newQty} onClick={handleUnavailableMenuOpen}>
            <ListItemIcon>
              <DoNotDisturbOnIcon />
            </ListItemIcon>
            <ListItemText>Unavailable</ListItemText>
          </MenuItem>
          <MenuItem id="comment" onClick={handleOpenLogDialog}>
            <ListItemIcon>
              <AddCommentIcon />
            </ListItemIcon>
            <ListItemText>Comment/Log</ListItemText>
          </MenuItem>
        </Menu>
        <Menu
          id="unavailable-menu"
          anchorEl={unavailableAnchorEl}
          open={!!unavailableAnchorEl}
          onClose={handleUnavailableMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem id="discontinued" onClick={handleUnavailableClick}>Discontinued</MenuItem>
          <MenuItem id="will-restock" onClick={handleRestockMenuOpen}>Will restock</MenuItem>
        </Menu>
        <Menu
          id="restock-menu"
          anchorEl={restockAnchorEl}
          open={!!restockAnchorEl}
          onClose={handleRestockMenuClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <MenuItem id="this-month" onClick={handleUnavailableClick} aria-label='nene'>
            <Box>
              <Typography>Within this month</Typography>
              <Typography variant="body2" color="textSecondary">
                Customer will not be contacted
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem id="next-month" onClick={handleUnavailableClick}>
            <Box>
              <Typography>Next month</Typography>
              <Typography variant="body2" color="textSecondary">
                Customer will be contacted
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem id="a-few-months" onClick={handleUnavailableClick}>
            <Box>
              <Typography>Within a few months</Typography>
              <Typography variant="body2" color="textSecondary">
                Customer will be contacted
              </Typography>
            </Box>
          </MenuItem>
        </Menu>
      </>,
      document.body
    )}
  </>
}

export default LineItem;