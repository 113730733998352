import { useState, useEffect } from 'react'

import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const DataButton = ({children, dataDescription, startIcon, endIcon, initialData, onDataChange = () => undefined, size, variant='outlined', color}) => {
    const [data, setData] = useState(initialData);
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState();

    const handleConfirmClick = () => {
        setData(formData);
        onDataChange(formData);
        handleModalClose();
    }

    const handleModalOpen = () => {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
    }

    return (
        <>
        <Button 
            startIcon={startIcon}
            endIcon={endIcon}
            onClick={handleModalOpen}
            size={size}
            variant={variant}
            color={color}
        >
            {children}
        </Button>
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack direction={'column'} spacing={2}>
                    <TextField
                        id="data-field"
                        label={dataDescription}
                        onChange={(evt) => setFormData(evt.target.value)}
                        fullWidth
                        defaultValue={data}
                    />
                    <Stack direction={'row'} justifyContent='flex-end' spacing={1}>
                        <Button onClick={handleModalClose}>Annuler</Button>
                        <Button onClick={handleConfirmClick} variant='contained'>Confirmer</Button>
                    </Stack>

                </Stack>
            </Box>
        </Modal>
        </>
    )
}

export default DataButton;