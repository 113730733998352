import {useState, useEffect} from 'react';
import { streamOrders } from '../../firebase.js';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import ExpandableOrderCard from './ExpandableOrderCard.js';

const Orders = ({setTitle, setLoading, setMenuItems}) => {
  const [orders, setOrders] = useState();

  useEffect(() => {
    setMenuItems();
  }, [setMenuItems]);
  
  useEffect(() => {
    setLoading(true);
    setTitle('COMMANDES');
    const unsubscribe = streamOrders((orders) => {
      setOrders(orders.docs.map(order => {
        return {...order.data(), id: order.id}
      }));
      setLoading(false);
    })
    return unsubscribe;
  }, []);

  return (
    <>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Stack sx={{margin: '14px auto'}} spacing={2}>
            {orders && orders.map(order => {
              return <ExpandableOrderCard key={order.id} order={order} />
            })}
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

export default Orders;