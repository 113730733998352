import { useState, useEffect } from 'react';
import { logout, streamNewOrder, db } from '../firebase.js';
import { collection, query, where, onSnapshot } from "firebase/firestore"; 

import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import FactoryIcon from '@mui/icons-material/Factory';
import GradingIcon from '@mui/icons-material/Grading';
import GroupIcon from '@mui/icons-material/Group.js';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import InventoryIcon from '@mui/icons-material/Inventory';
import LinearProgress from '@mui/material/LinearProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import WarehouseIcon from '@mui/icons-material/Warehouse';

import Orders from './OrdersPage/Orders.js';
import ProdOrder from './ProdOrderPage/ProdOrder.js';
import BrazilOrders from './StockOrderPage/BrazilOrders.js';
import PendingProducts from './PendingProductsPage/PendingProducts.js';
import Users from './UsersPage/Users.js';
import { Avatar, Stack, Typography } from '@mui/material';
import Inventory from './InventoryPage/Inventory.js';
import Analytics from './Analytics/Analytics.js';
import { stringAvatar } from '../utils/users.js';

function Dashboard({user}) {
    const [value, setValue] = useState(0);
    const [viewComponent, setViewComponent] = useState(null);
    const [newOrdersCount, setNewOrdersCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [title, setTitle] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [newReleasesCount, setNewReleasesCount] = useState();

    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleLogout = () => {
      logout();
      handleClose();
    }

    useEffect(() => {
      if (user.isAdmin) {
        const q = query(collection(db, 'brazil-inventory-pending'), where("status", "==", "missing"));
        return onSnapshot(q, (querySnapshot) => setNewReleasesCount(querySnapshot.size));
      }
    }, [user])

    useEffect(() => {
      let newComponent;
      switch (value) {
        case 0:
          newComponent = <BrazilOrders setTitle={setTitle} setLoading={setLoading} setMenuItems={setMenuItems} />
          break;
        case 1:
          newComponent = <ProdOrder setTitle={setTitle} setLoading={setLoading} setMenuItems={setMenuItems} />
          break;
        case 2:
          newComponent = <Orders setTitle={setTitle} setLoading={setLoading} setMenuItems={setMenuItems} />
          break;
        case 3: 
          newComponent = <Inventory setTitle={setTitle} setLoading={setLoading} setMenuItems={setMenuItems} />
          break;
        case 4:
          newComponent = <PendingProducts setTitle={setTitle} setLoading={setLoading} setMenuItems={setMenuItems} />
          break;
        case 5: 
          newComponent = <Users setTitle={setTitle} setLoading={setLoading} setMenuItems={setMenuItems} />
          break;
        case 6:
          newComponent = <Analytics setTitle={setTitle} setLoading={setLoading} setMenuItems={setMenuItems} />
      }
      setViewComponent(newComponent);
    }, [value, user])

    useEffect(() => {
      if (isAuthorised()) {
        const unsubscribe = streamNewOrder((docs) => {

          setNewOrdersCount(docs.size);
        });
        return unsubscribe;
      }
    }, [user]);

    const isAuthorised = () => {
      return user.isAdmin || user.isStaff || user.isSupplier;
    }

    const unauthorised = (
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'100vh'}
      >
        <Stack direction={'row'} alignItems={'center'} spacing={3}><Typography fontSize={40}>&#9760;</Typography><Typography component={'h1'} fontSize={24} textAlign={'center'}>Accès non autorisé &#128534;</Typography></Stack>
      </Box>
    )
    
    return (
    <Box sx={{ pb: 7 }}>
      <AppBar position="fixed" color="primary" sx={{ top: '0', bottom: 'auto' }}>
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            {title}
          </Typography>
          {menuItems}
          <Avatar
            id="account-button"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            {...stringAvatar(user.displayName)}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem disabled>{user.email}</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
        {loading && <Box sx={{width: "100%"}}><LinearProgress /></Box>}
      </AppBar>
      <Toolbar />
      {isAuthorised() ? viewComponent: unauthorised}
      {isAuthorised() && (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <BottomNavigationAction label="Stock" icon={<WarehouseIcon />} />
            {user?.isAdmin && <BottomNavigationAction label="Production" icon={<FactoryIcon />} />}
            <BottomNavigationAction label="Commandes" icon={<Badge invisible={!newOrdersCount} badgeContent={newOrdersCount} color="success"><GradingIcon /></Badge>} />
            {user?.isAdmin && <BottomNavigationAction label="Inventaire" icon={<InventoryIcon />} />}
            {user?.isAdmin && 
              <BottomNavigationAction label="Nouveautés" icon={<Badge badgeContent={newReleasesCount} color='success'>
                <NewReleasesIcon />
              </Badge>
              } />}
            {user?.isAdmin && <BottomNavigationAction label="Utilisateurs" icon={<GroupIcon />} />}
            {user?.isAdmin && <BottomNavigationAction label="Analytics" icon={<QueryStatsIcon />} />}
          </BottomNavigation>
        </Paper>
      )}
    </Box>
    );
  }
  
  export default Dashboard;