import {useState, useEffect} from 'react';
import { streamPendingProducts } from '../../firebase.js';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ProductCard from './ProductCard.js';
import Stack from '@mui/material/Stack';

const PendingProducts = ({setTitle, setLoading, setMenuItems }) => {
  const [pendingProducts, setPendingProducts] = useState();
  const [open, setOpen] = useState();
  const [dialogTitle, setDialogTitle] = useState();
  const [dialogContent, setDialogContent] = useState();

  useEffect(() => {
    setMenuItems();
  }, [setMenuItems]);
  
  useEffect(() => {
    setLoading(true);
    setTitle('PRODUITS EN ATTENTE');
    const unsubscribe = streamPendingProducts((pendingProducts) => {
      setPendingProducts(pendingProducts.docs.map(product => product.data()));
      setLoading(false);
    })
    return unsubscribe;
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleResponse = (data) => {
    console.log({data})
    if (data.error) {
      setDialogTitle('ERROR');
    } else {
      setDialogTitle("SUCCESS");
    }
    setDialogContent(<>
      <List dense>
        {data.data.map((change, index) => (
          <ListItem id={index} secondaryAction={change.success ? <CheckIcon color='success' /> : change.success === false ? <CloseIcon color='error' /> : <InfoIcon />}>{change.message}</ListItem>
        ))}
      </List>
      {data.error && <Alert severity={'error'}>{data.error}</Alert>}
    </>);
    setOpen(true);
  }

  return (
    <>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Stack sx={{maxWidth: '600px', margin: '14px auto'}} spacing={2}>
            {pendingProducts && pendingProducts.map(product => {
              return <ProductCard key={product.barcode} product={product} onResponse={handleResponse} />
            })}
          </Stack>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          {dialogContent}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PendingProducts;