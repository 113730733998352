import { useEffect, useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import TextField from '@mui/material/TextField';
import OrderTable from '../ProdOrderPage/OrderTable';
import { createOrder, adjustFieldQty } from '../../firebase';
import { regionFromCountryCode } from '../../utils/regionFromCountryCode';
import { Box } from '@mui/material';

function CreateOrderMenuItem({lineItems, closeMenu}) {
    const [showModal, setShowModal] = useState(false);
    const [reference, setReference] = useState('');
    const [order, setOrder] = useState();
    const [creating, setCreating] = useState(false);

    useEffect(() => {
        // Get order reference
        const today = new Date();
        const lastMonthDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthStr = new Intl.DateTimeFormat('en-CA', {month: '2-digit'}).format(lastMonthDate).toUpperCase();
        setReference(`${lastMonthDate.getFullYear().toString().substr(-2)}${lastMonthStr}S`);
    }, []);

    useEffect(() => {
        /* 
                Format order data as will be uploaded to firebase.
                {
                    reference
                    createdAt
                    stores [
                        name
                    ]
                    items [{
                        barcode
                        name
                        size
                        sku
                        qty {
                            store: qty
                        }
                    }]
                    notes [{
                        user
                        note
                    }]
                }
            */

        // Consolidate quantities
        const order = {
            reference,
            createdAt: Date.now(),
            stores: [],
            notes: [],
            lineItems: [],
        };
        const skus = {};

        for (let { barcode, docId, name, reservedQty, shippingCountryCode, sku } of lineItems) {
            // try to extract size
            const size = sku.match(/-([SML]|XS|XL|\d{2})$/i)?.[1] || null;
            name = name || sku.match(/(.+)(?:-(?:[SML]|XS|XL|\d{2}))$/i)?.[1] || null;
            if (!skus[sku]) {
                skus[sku] = {
                    barcode,
                    name: name || '',
                    size,
                    sku,
                    qty: {},
                };
            }
            const storeName = regionFromCountryCode(shippingCountryCode).toLowerCase();
            if (!order.stores.includes(storeName)) {
                order.stores.push(storeName);
            }
            skus[sku].qty[storeName] = (skus[sku].qty[storeName] || 0) + reservedQty;
            order.lineItems.push({docId, qty: reservedQty});
        }
        order.items = Object.entries(skus).map(([sku, data]) => {
            return { ...data };
        });
        setOrder(order);
    }, [lineItems, reference])

    const handleOpenModal = () => {
        setCreating(false);
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const handleCreateOrder = async () => {
        setCreating(true);
        try {
            await createOrder(reference, order, 'stock');
        } catch (error) {
            console.error(error);
        }
        handleCloseModal();
    }

    const handleClickMenuItem = () => {
        !showModal && handleOpenModal();
        closeMenu();
    }

    const renderModal = (
        <Dialog
            open={showModal}
            onClose={handleCloseModal}
            maxWidth={'full'}
        >
        {!creating ? 
            <>
                <DialogTitle>Commande Stock</DialogTitle>
                <DialogContent>
                <p>Une commande sera créée à partir des articles séparées.</p>
                <Grid container spacing={2} sx={{mt: '4px'}}>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            required
                            id='reference'
                            label='Reference'
                            defaultValue={reference}
                            placeholder="yyyyMMS"
                            onChange={(evt) => setReference(evt.target.value)}
                        />
                    </Grid>
                </Grid>
                <Box sx={{paddingTop:"30px"}}>
                    <OrderTable order={order} />
                </Box>
                
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Annuler</Button><Button onClick={handleCreateOrder}>Créer</Button>
                </DialogActions>
            </> :
            <>
                <DialogTitle>Veuillez Patienter</DialogTitle>
                <DialogContent><p>La création de la commande peut prendre plusieurs secondes. Ne fermez pas cette page.</p></DialogContent>
            </>}
        </Dialog>
    )

    return (
        <MenuItem onClick={handleClickMenuItem} disabled={!lineItems.length}>
            <ListItemIcon><ShoppingCartCheckoutIcon /></ListItemIcon>
            <ListItemText>Créer une commande (articles séparées)</ListItemText>
            {renderModal}
        </MenuItem>
    )
}

export default CreateOrderMenuItem;