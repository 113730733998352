import {useState, useEffect} from 'react';
import {
  httpsCallable
} from 'firebase/functions';
import {
  collection,
  doc,
  onSnapshot,
  getDocs,
  orderBy,
} from 'firebase/firestore';
import { db, functions } from '../../firebase';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import SingleStat from './SingleStat';
import Stack from '@mui/material/Stack';
import StatCard from './StatCard';
import { Box, Button } from '@mui/material';

const Analytics = ({setTitle, setLoading, setMenuItems}) => {
  const [ltv, setLtv] = useState();

  useEffect(() => {
    setMenuItems();
  }, [setMenuItems]);
  
  useEffect(() => {
    setTitle('ANALYTICS');
    // subscribe to data
    const date = (new Date()).toISOString().split("T")[0];
    const unsubscribe = onSnapshot(doc(db, "customer-ltv-view", date), (doc) => {
      console.log("Current data: ", doc.data());
      if (!doc.exists()) {
        // create the doc
        handleCreateCLVView();
      } else {
        setLtv(doc.data());
      }
    });
    return unsubscribe;
  }, []);

  const handleCreateCLVView = () => {
    const createCLVView = httpsCallable(functions, 'analytics-createCLVView');
    return createCLVView();
  }

  return (
    <>
      {!ltv && <Box pt={2}>
        <Button onClick={handleCreateCLVView}>Update Data</Button>
      </Box>}
      <Grid container justifyContent="left" spacing={2} py={2}>
        <Grid item xs={12} sm={3}>
            <StatCard title='CSV' subtitle='All-time'>{ltv ? `${ltv.baseCurrency} ${ltv.averageLTVs.averageAllTimeLTV.toFixed(2)}` : <CircularProgress />}</StatCard>
        </Grid>
        <Grid item xs={12} sm={3}>
            <StatCard title='CSV' subtitle='1 year'>{ltv ? `${ltv.baseCurrency} ${ltv.averageLTVs.average1YearLTV.toFixed(2)}` : <CircularProgress />}</StatCard>
        </Grid>
        <Grid item xs={12} sm={3}>
            <StatCard title='CSV' subtitle='90 days'>{ltv ? `${ltv.baseCurrency} ${ltv.averageLTVs.average90DaysLTV.toFixed(2)}` : <CircularProgress />}</StatCard>
        </Grid>
        <Grid item xs={12} sm={3}>
            <StatCard title='CSV' subtitle='60 days'>{ltv ? `${ltv.baseCurrency} ${ltv.averageLTVs.average60DaysLTV.toFixed(2)}` : <CircularProgress />}</StatCard>
        </Grid>
        <Grid item xs={12} sm={3}>
            <StatCard title='CSV' subtitle='30 days'>{ltv ? `${ltv.baseCurrency} ${ltv.averageLTVs.average30DaysLTV.toFixed(2)}` : <CircularProgress />}</StatCard>
        </Grid>
      </Grid>
    </>
  )
}

export default Analytics;