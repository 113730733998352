import React, { useState } from 'react';
import {createShopifyDraft} from '../../firebase.js';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';


const ProductCard = ({ product, onResponse }) => {
  const [status, setStatus] = useState();
  const [errorMsg, setErrorMsg] = useState();

  const handleClick = async () => {
    // Call function to create draft product on Shopify;
    setStatus('loading');
    try {
      const { data } = await createShopifyDraft(product.barcode);
      onResponse(data);
      setStatus('done');
    }
    catch (error) {
      console.log({error});
      setStatus('error');
      setErrorMsg(`${error.name}: ${error.message}`);
    }
  }
  return (
  <Card sx={{ display: 'flex' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flex: '1 0 auto' }}>
        <Typography component="div" variant="h5">
          {product.sku}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" component="div">
          Barcode: {product.barcode}
        </Typography>
        {errorMsg && <Typography variant="body1" color="error" component="div">
          Error: {errorMsg}
        </Typography>}
      </CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
        <Button onClick={handleClick} disabled={status === 'loading' || status === 'done'} variant='contained' color='success'>Créer Shopify Draft</Button>
      </Box>
    </Box>
    <CardMedia
      component="img"
      sx={{ width: 151 }}
      image={`http://taygra-store.com/img/p/${product.brazilIdProduct}-${product.latestImage}.jpg`}
      alt={product.sku}
    />
  </Card>
  )
}

export default ProductCard;