import { Typography, Stack, Box } from "@mui/material";

import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import EastIcon from '@mui/icons-material/East';
import Grid from "@mui/material/Unstable_Grid2/Grid2";

const SingleStat = ({current, currentTitle, currentSuffix, past, pastTitle, inline=false, accentColor}) => {
    const largeFont = inline ? "13px" : "22px";
    const smallFont = "13px";
    const direction = inline ? "row-reverse" : "column"
    const horizontalSpacing = inline ? 1 : 0;
    let ratio;
    if (typeof current === 'number' && !isNaN(current) && typeof past === 'number' && !isNaN(past)) {
        if (current !== 0 && past !== 0) {
            // current and past are non-zero, ratio is current/past
            ratio = (current - past) / past * 100;
        } else if (current === 0 && past !== 0) {
            // current is zero but past is not, ratio is -100
            ratio = -100;
        } else if (current !== 0 && past === 0) {
            // current is non-zero but past is zero, ratio is 100
            ratio = 100;
        } else {
            // current and past are zero, ratio is -
            ratio = '–';
        }
    }
    return (
        <Stack direction="row" spacing={1}>
            <Stack direction="column" flexGrow={1}>
                <Stack direction={direction} spacing={horizontalSpacing} justifyContent="space-between" alignContent="stretch">
                    <Typography fontSize={smallFont} color={accentColor} minWidth={40} textAlign="right">{currentSuffix}</Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography fontSize={largeFont} fontWeight="bold" color={accentColor}>{current}</Typography>
                    </Stack>
                    {inline && <Box borderBottom="2px dotted grey" position="relative" bottom={"5px"} flexGrow={1}></Box>}
                {currentTitle && <Typography fontSize={smallFont} fontWeight="normal" color={accentColor}>{currentTitle}</Typography>}
                </Stack>
                <Stack direction={direction} spacing={horizontalSpacing} justifyContent="space-between">
                    {typeof past === "number" && !isNaN(past) && <Typography className="PAST" fontSize={largeFont} fontWeight="normal" color="GrayText">{past}</Typography>}
                    {pastTitle && <Typography fontSize={smallFont} fontWeight="normal" color="GrayText">{pastTitle}</Typography>}
                </Stack>
            </Stack>
            {typeof ratio === "number" && !isNaN(ratio) && <Stack direction="row" spacing={0.5} alignItems="center">
                    {ratio > 0 ? <NorthEastIcon fontSize={smallFont} sx={{color: "green"}} /> : (ratio < 0 ? <SouthEastIcon fontSize={smallFont} sx={{color:"red"}} /> : <EastIcon fontSize={smallFont} />)}
                <Typography fontSize={smallFont} color={ratio > 0 ? "green" : ratio < 0 ? "red" : undefined}>{Math.abs(ratio).toFixed(1)}%</Typography>
            
            </Stack>}
            {typeof ratio === "string" && <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography fontSize={smallFont}>{ratio}</Typography>
            
            </Stack>}
        </Stack>
    );
}

export default SingleStat;